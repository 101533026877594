import {
  ApiBaseDataFromJSON,
  ApiBookedItem,
  ApiBooking,
  ApiClientType,
  ApiCustomer,
  ApiGender,
  ApiItem,
  ApiItemType,
  ApiNote,
  ApiNoteType,
  ApiPackageModel,
  ApiPaymentState,
  ApiPrice
} from '@ibe/api';
import BookingTranslationsUtil from './BookingTranslationsUtil';
import { ReactSelectOption, Buyer } from '@ibe/components';

interface BookingIdentifier {
  bookingNumber: string;
  lastName: string | null;
  salesChanel: string | null;
  packageCode: string | null;
}

export interface CustomItemDescriptions {
  freeInsuranceLabel?: string;
  paidInsuranceLabel?: string;
}

abstract class BookingUtil {
  public static getPaymentStatus(paymentsState: ApiPaymentState): string | null {
    const bookingTranslationsUtil = BookingTranslationsUtil();

    if (paymentsState) {
      switch (paymentsState) {
        case ApiPaymentState.AUTHORIZED:
          return bookingTranslationsUtil.mapPaymentStatus(ApiPaymentState.AUTHORIZED);
        case ApiPaymentState.OPEN:
          return bookingTranslationsUtil.mapPaymentStatus(ApiPaymentState.OPEN);
        case ApiPaymentState.PAID:
          return bookingTranslationsUtil.mapPaymentStatus(ApiPaymentState.PAID);
        case ApiPaymentState.UNDEFINED:
          return bookingTranslationsUtil.mapPaymentStatus(ApiPaymentState.UNDEFINED);
        default:
          return null;
      }
    }
    return null;
  }

  public static getParticipants(pricesByPersonId: { [key: string]: ApiPrice }): string {
    function mapResult(result: Array<Array<number>>): string {
      let participantsString = '';

      for (let i = 0; i < result.length; i += 1) {
        if (result[i].length === 1) {
          participantsString += `${result[i][0]}, `;
        } else {
          participantsString += `${result[i][0]}-${result[i][result[i].length - 1]}, `;
        }
      }

      return participantsString.substring(0, participantsString.length - 2);
    }

    const participants: Array<number> = [];

    Object.keys(pricesByPersonId).forEach(item => participants.push(Number(item)));

    if (participants.length === 1) {
      return String(participants[0]);
    }

    // Operations in 'reduce' method force to use type 'any' otherwise error: "sth does not exists on type never" occurs
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = participants.reduce((r: any[], n) => {
      const lastSubArray = r[r.length - 1];

      if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
        r.push([]);
      }

      r[r.length - 1].push(n);

      return r;
    }, []);

    return mapResult(result);
  }

  public static getBookingNotes(booking: ApiBooking): Array<ApiNote> | undefined {
    return booking.notes;
  }

  public static createBookingIdentifier(booking?: ApiBooking): BookingIdentifier | null {
    if (!booking) {
      return null;
    }
    const lastNameOfFirstParticipant =
      booking.travelers?.find(traveler => parseInt(traveler.id, 10) === 1)?.lastName || null;
    const packageModel = booking.items?.find(
      item => item.type === 'PackageModel'
    ) as ApiPackageModel;

    return {
      bookingNumber: booking.bookingNumber,
      lastName: lastNameOfFirstParticipant,
      salesChanel: booking.salesChannel || null,
      packageCode: packageModel?.code || null
    };
  }

  public static buildNote(text: string): ApiNote {
    return {
      id: '',
      type: ApiNoteType.INFO,
      text
    };
  }

  public static getBookedItemDescription(
    bookedItem: ApiBookedItem,
    customItemDescriptions?: CustomItemDescriptions
  ): string {
    if (bookedItem.itemType === ApiItemType.HOTEL) {
      return `${bookedItem.name} ${bookedItem.description}`;
    } else if (bookedItem.itemType === ApiItemType.INSURANCE) {
      if (bookedItem.price.finalPrice > 0) {
        if (customItemDescriptions?.paidInsuranceLabel) {
          return customItemDescriptions?.paidInsuranceLabel;
        }
      } else {
        if (customItemDescriptions?.freeInsuranceLabel) {
          return customItemDescriptions?.freeInsuranceLabel;
        }
      }
    }

    return bookedItem.description;
  }

  public static isBookingWithFees(booking: ApiBooking): boolean {
    if (booking.price?.finalPrice) {
      return booking.price.finalPrice > 0.0;
    }
    return false;
  }

  public static getServiceKeys(booking: ApiBooking, types: Array<string>): Array<string> {
    const serviceKeys: Array<string> = [];
    types.forEach(type => {
      const item = booking.items?.find(element => element.type === type);
      if (item) {
        serviceKeys.push(item.id);
      }
    });
    return serviceKeys;
  }

  public static mapPaymentMethodsToSelectOptions(
    options: Map<string, string>
  ): ReactSelectOption[] {
    if (options.size > 0) {
      const mappedOptions: ReactSelectOption[] = [];

      options.forEach((value, key) => {
        mappedOptions.push({ value: key, label: value });
      });

      return mappedOptions;
    }

    return [];
  }

  public static getPaymentMethodByValue(
    value: string,
    options: Map<string, string>
  ): ReactSelectOption {
    let methodKey = '';
    options.forEach((val, key) => {
      if (val === value) {
        methodKey = key;
      }
    });
    return { label: methodKey, value };
  }

  public static mapBuyerToCustomer(buyer: Buyer): ApiCustomer {
    return {
      address: {
        street: buyer.streetAddress,
        city: buyer.city,
        countryCode: buyer.country.code,
        regionCode: buyer.state.code,
        region: buyer.state.description,
        postalCode: buyer.zipCode
      },
      age: 22,
      birthDate: '',
      communicationDetails: {
        phone: buyer.phone,
        email: buyer.email
      },
      taxNumber: buyer.fiscalCode,
      documentNumber: '',
      gender: ApiGender.OTHER,
      id: '',
      lastName: buyer.lastName,
      salutation: buyer.salutation.code,
      title: '',
      type: ApiClientType.Customer,
      firstName: buyer.firstName
    };
  }

  public static mapCustomerToBuyer(customer: ApiCustomer): Buyer {
    return {
      firstName: customer.firstName,
      lastName: customer.lastName,
      salutation: ApiBaseDataFromJSON({ code: customer.salutation, description: '' }),
      streetAddress: customer.address.street,
      zipCode: customer.address.postalCode,
      city: customer.address.city,
      country: ApiBaseDataFromJSON({ code: customer.address.countryCode, description: '' }),
      state: ApiBaseDataFromJSON({ code: customer.address.regionCode, description: '' }),
      fiscalCode: customer.taxNumber || '',
      phone: customer.communicationDetails.phone || '',
      email: customer.communicationDetails.email || '',
      confirmEmail: customer.communicationDetails.email || ''
    };
  }

  public static getRelatedItem(
    booking: ApiBooking | null,
    bookedItemIdParent: string
  ): ApiItem | null {
    if (!booking) return null;
    const relatedItem = booking.items.find(value => value.id === bookedItemIdParent);
    return relatedItem || null;
  }

  public static isBookingFullyPaid(bookingObject: ApiBooking) {
    if (bookingObject.openAmount) {
      return bookingObject.openAmount.finalPrice <= 0;
    }
    return true;
  }
}

export default BookingUtil;
