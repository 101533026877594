/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-08-07 12:14:09 */

const Keys = {
  Login: 'Login',
  Logout: 'Logout',
  YourBooking: 'YourBooking',
  navigationBackModal: {
    header: 'navigationBackModal.header',
    content: 'navigationBackModal.content',
    back: 'navigationBackModal.back'
  },
  errorModalButtonLabel: 'errorModalButtonLabel',
  errorModalInformation: 'errorModalInformation',
  salutationPrefix: 'salutationPrefix',
  salutationSuffix: 'salutationSuffix'
}; export default Keys;