/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-08-07 12:14:09 */

const Keys = {
  tabs: {
    bookingOverview: 'tabs.bookingOverview',
    participants: 'tabs.participants',
    bookedServices: 'tabs.bookedServices',
    address: 'tabs.address'
  },
  bookingOverview: {
    bookingNumber: 'bookingOverview.bookingNumber',
    status: 'bookingOverview.status',
    bookingDate: 'bookingOverview.bookingDate',
    numberOfPax: 'bookingOverview.numberOfPax',
    arrival: 'bookingOverview.arrival',
    departure: 'bookingOverview.departure',
    total: 'bookingOverview.total',
    tax: 'bookingOverview.tax',
    paymentStatus: 'bookingOverview.paymentStatus'
  },
  participants: {
    number: 'participants.number',
    salutation: 'participants.salutation',
    firstName: 'participants.firstName',
    lastName: 'participants.lastName',
    age: 'participants.age'
  },
  bookedServices: {
    number: 'bookedServices.number',
    units: 'bookedServices.units',
    description: 'bookedServices.description',
    date: 'bookedServices.date',
    duration: 'bookedServices.duration',
    participants: 'bookedServices.participants',
    price: 'bookedServices.price',
    day: 'bookedServices.day',
    day_plural: 'bookedServices.day_plural',
    night: 'bookedServices.night',
    night_plural: 'bookedServices.night_plural',
    freeInsuranceLabel: 'bookedServices.freeInsuranceLabel',
    paidInsuranceLabel: 'bookedServices.paidInsuranceLabel'
  },
  address: {
    salutation: 'address.salutation',
    firstName: 'address.firstName',
    lastName: 'address.lastName',
    email: 'address.email',
    phone: 'address.phone',
    country: 'address.country',
    streetNo: 'address.streetNo',
    city: 'address.city',
    postalCode: 'address.postalCode',
    state: 'address.state',
    modal: {
      header: 'address.modal.header',
      body: 'address.modal.body',
      close: 'address.modal.close'
    },
    buttons: { save: 'address.buttons.save', cancel: 'address.buttons.cancel' },
    changeAddressOnDocumentsLink: 'address.changeAddressOnDocumentsLink',
    tabs: {
      contactDetails: 'address.tabs.contactDetails',
      addressDetails: 'address.tabs.addressDetails'
    },
    error: 'address.error'
  },
  statuses: {
    bookingStatuses: {
      OK: 'statuses.bookingStatuses.OK',
      OPEN: 'statuses.bookingStatuses.OPEN',
      PENDING: 'statuses.bookingStatuses.PENDING',
      BOOKED: 'statuses.bookingStatuses.BOOKED',
      OPTION: 'statuses.bookingStatuses.OPTION',
      CANCELLED: 'statuses.bookingStatuses.CANCELLED'
    },
    paymentStatuses: {
      OPEN: 'statuses.paymentStatuses.OPEN',
      PAID: 'statuses.paymentStatuses.PAID',
      UNDEFINED: 'statuses.paymentStatuses.UNDEFINED',
      AUTHORIZED: 'statuses.paymentStatuses.AUTHORIZED'
    },
    unknown: 'statuses.unknown'
  },
  payment: {
    type: {
      UNDEFINED: 'payment.type.UNDEFINED',
      CREDITCARD: 'payment.type.CREDITCARD',
      BANKTRANSFER: 'payment.type.BANKTRANSFER',
      VOUCHER: 'payment.type.VOUCHER',
      CASH: 'payment.type.CASH'
    }
  },
  buttons: {
    CANCEL: 'buttons.CANCEL',
    APPLY: 'buttons.APPLY',
    HERE: 'buttons.HERE',
    changeParticipantsAge: 'buttons.changeParticipantsAge',
    OK: 'buttons.OK'
  },
  ageChange: {
    header: 'ageChange.header',
    buttonLabel: 'ageChange.buttonLabel',
    information: 'ageChange.information',
    phoneLabel: 'ageChange.phoneLabel',
    phoneNumber: 'ageChange.phoneNumber',
    emailLabel: 'ageChange.emailLabel',
    emailAddress: 'ageChange.emailAddress'
  },
  general: { nothingToShow: 'general.nothingToShow' },
  editModal: {
    editParticipant: 'editModal.editParticipant',
    error: 'editModal.error',
    cancel: 'editModal.cancel',
    save: 'editModal.save',
    requiredField: 'editModal.requiredField'
  },
  nameChangeModal: {
    information: 'nameChangeModal.information',
    cancellationFeeInfo: 'nameChangeModal.cancellationFeeInfo',
    feeAmount: 'nameChangeModal.feeAmount',
    feeAgreement: 'nameChangeModal.feeAgreement',
    selectPaymentMethod: 'nameChangeModal.selectPaymentMethod',
    paymentSuccess: 'nameChangeModal.paymentSuccess',
    paymentError: 'nameChangeModal.paymentError',
    paymentMethodsPlaceholder: 'nameChangeModal.paymentMethodsPlaceholder',
    buttons: {
      back: 'nameChangeModal.buttons.back',
      agreeAndPay: 'nameChangeModal.buttons.agreeAndPay'
    }
  },
  cartView: {
    headline: 'cartView.headline',
    cancelButtonLabel: 'cartView.cancelButtonLabel',
    addExtrasButtonLabel: 'cartView.addExtrasButtonLabel',
    multiRoomPrefix: 'cartView.multiRoomPrefix',
    adult: 'cartView.adult',
    adult_plural: 'cartView.adult_plural',
    child: 'cartView.child',
    child_plural: 'cartView.child_plural',
    infant: 'cartView.infant',
    infant_plural: 'cartView.infant_plural',
    bookingSummaryInfo: 'cartView.bookingSummaryInfo'
  }
}; export default Keys;