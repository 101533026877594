import { MenuItem } from '@ibe/components';
import { PageUrl } from '../../../pages';
import { ApiBooking, ApiBookingState, ApiImage } from '@ibe/api';
import FeatureConfiguration from '../../../Models/CMS/FeatureConfiguration';
import dayjs from 'dayjs';
import BookingUtils from '../../../Util/BookingUtils';

export default abstract class MMBUtil {
  public static sortNavigationMapByTiles(
    navMap: Map<string, MenuItem[]>,
    tiles: Map<string, ApiImage>,
    featureConfiguration: FeatureConfiguration,
    buildSection: (key: string) => string,
    booking?: ApiBooking,
    isAdditionalNightAvailable?: boolean,
    isCancellationIntoVoucherAvailable?: boolean,
    isCancellationAvailable?: boolean,
    bookingFulfillsRebookingRules?: boolean
  ): Map<string, MenuItem[]> {
    if (tiles.size === 0) {
      return navMap;
    }
    const sortedNavigation = new Map<string, MenuItem[]>();
    const dashboardMenuItem = navMap.get(`${PageUrl.DASHBOARD}_key`);
    if (dashboardMenuItem) {
      sortedNavigation.set(`${PageUrl.DASHBOARD}_key`, dashboardMenuItem);
    }
    tiles.forEach((value, key) => {
      const menuItems = navMap.get(`/${key}_key`);
      if (menuItems) {
        sortedNavigation.set(`/${key}_key`, menuItems);
      }
    });

    return MMBUtil.filterNavigationMenuItems(
      sortedNavigation,
      featureConfiguration,
      buildSection,
      booking,
      isAdditionalNightAvailable,
      isCancellationIntoVoucherAvailable,
      isCancellationAvailable,
      bookingFulfillsRebookingRules
    );
  }

  public static filterNavigationMenuItems(
    navMap: Map<string, MenuItem[]>,
    featureConfiguration: FeatureConfiguration,
    buildSection: (key: string) => string,
    booking?: ApiBooking,
    isAdditionalNightAvailable?: boolean,
    isCancellationIntoVoucherAvailable?: boolean,
    isCancellationAvailable?: boolean,
    bookingFulfillsRebookingRules?: boolean
  ) {
    const filtered_navMap = new Map<string, MenuItem[]>();
    const manageBookingNavMapKey = `${PageUrl.MANAGE_BOOKING}_key`;
    const changeBookingNavMapKey = `${PageUrl.CHANGE_BOOKING}_key`;
    const extraServicesNavMapKey = `${PageUrl.EXTRA_SERVICES}_key`;

    navMap.forEach((menuItems, key) => {
      let new_menuItems: MenuItem[] = menuItems;

      if (manageBookingNavMapKey == key) {
        if (!featureConfiguration.showBookingOverview) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.MANAGE_BOOKING + buildSection('booking-overview')
          );
        }
        if (!featureConfiguration.showParticipants) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.MANAGE_BOOKING + buildSection('participants')
          );
        }
        if (!featureConfiguration.showBookedServices) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.MANAGE_BOOKING + buildSection('booked-services')
          );
        }
        if (!featureConfiguration.showAddresses) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.MANAGE_BOOKING + buildSection('address')
          );
        }
      }

      if (changeBookingNavMapKey == key) {
        if (!featureConfiguration.additionalNightAvailable || !isAdditionalNightAvailable) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.CHANGE_BOOKING + buildSection('additionalNight')
          );
        }

        if (!featureConfiguration.rebookingAvailable || !bookingFulfillsRebookingRules) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.CHANGE_BOOKING + buildSection('rebook')
          );
        }
        if (
          !featureConfiguration.cancellationIntoVoucherAvailable ||
          !isCancellationIntoVoucherAvailable
        ) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.CHANGE_BOOKING + buildSection('voucher')
          );
        }
        if (!featureConfiguration.bookingCancellationAvailable || !isCancellationAvailable) {
          new_menuItems = new_menuItems.filter(
            menuItem => menuItem.link != PageUrl.CHANGE_BOOKING + buildSection('cancel-booking')
          );
        }
      }

      if (
        extraServicesNavMapKey == key &&
        !this.isAddExtraTileAvailable(featureConfiguration, booking)
      ) {
        new_menuItems = new_menuItems.filter(menuItem => menuItem.link != PageUrl.EXTRA_SERVICES);
      }

      if (
        changeBookingNavMapKey == key ||
        manageBookingNavMapKey == key ||
        extraServicesNavMapKey == key
      ) {
        if (new_menuItems.length > 1) {
          filtered_navMap.set(key, new_menuItems);
        }
      } else {
        filtered_navMap.set(key, new_menuItems);
      }
    });
    return filtered_navMap;
  }

  public static isAddExtraTileAvailable(
    featureConfiguration: FeatureConfiguration,
    booking?: ApiBooking
  ) {
    return (
      dayjs(booking?.travelStartDate).isAfter(
        dayjs().add(featureConfiguration.addExtrasDaysBeforeArrival, 'day')
      ) &&
      BookingUtils.getPackageFromBooking(booking) != undefined &&
      booking?.bookingState !== ApiBookingState.CANCELLED
    );
  }
}
