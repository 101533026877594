import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import styled from '@emotion/styled';

import {
  BreadcrumbProps,
  ImageProgressbar,
  MenuItem,
  packageCartStoreContext,
  useAuth,
  useTranslation,
  Error,
  useMMBBookingService,
  VoucherCancellationType
} from '@ibe/components';
import { faArrowRight, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import fallback from '../../Translations/generated/header.en.json';
import Keys from '../../Translations/generated/header.en.json.keys';
// eslint-disable-next-line import/no-cycle
import { b2bPages, innerManageBookingPages, manageBookingPages, PageUrl } from '../../pages';
import SummaryPopoverWithActions from '../../Components/SummaryPopoverWithActions/SummaryPopoverWithActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMount } from 'react-use';
import { LocalStorageKey } from '../../Storage/LocalStorageKeyRegistry';
import PageUtil from '../../Util/PageUtil';
import ManageBookingLink from '../../Components/ManageBookingLink/ManageBookingLink';
import { ApiIncludedService, ApiPackageModel, ApiRole } from '@ibe/api';
import AgencyMenu from '../../Components/AgencyMenu/AgencyMenu';
import WorkflowTabs from '../../Components/WorkflowTabs/WorkflowTabs';
import useCms from '../../Hooks/useCms';
import useAuthSessionPersistence from '../../Hooks/useAuthSessionPersistence';
import MultiLevelNavigationMenu from '../../Components/MMB/Navigation/MultiLevelNavigationMenu';
import MMBUtil from '../../Components/MMB/Util/MMBUtil';
import useNavigationMap from '../../Hooks/useNavigationMap';
import { QueryUtils } from '../../Util/QueryUtils';
import useExtendedQuery from '../../Hooks/useExtendedQuery';
import dayjs from 'dayjs';
import BookingUtil from '../../Components/MMB/Util/BookingUtil';

const HeaderContainer = styled.div`
  margin: auto;
  padding: 1rem 15px;
`;

const Header = observer(
  ({
    breadcrumbProps,
    onGoToCheckout,
    editBookingItemExtension,
    packageIncludedServices,
    packageModel
  }: {
    breadcrumbProps?: BreadcrumbProps;
    onGoToCheckout?: () => Promise<void>;
    editBookingItemExtension?: (itemIDs: Array<string>) => void;
    packageIncludedServices?: Array<ApiIncludedService>;
    packageModel?: ApiPackageModel;
  }) => {
    const { t } = useTranslation('header', fallback);
    const { authState } = useAuth();
    const history = useHistory();
    const location = useLocation<{ noNavigationBackModal: boolean }>();
    const [showNavigationBackModal, setShowNavigationBackModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showWorkflowTabs, setShowWorkflowTabs] = useState<boolean>(false);
    const [prevPagePath] = useAuthSessionPersistence<string>(LocalStorageKey.PAGE_URL);
    const [isCartDisplayed, setIsCartDisplayed] = useState<boolean>(false);
    const packageCartContext = useContext(packageCartStoreContext);
    const packageCartStore = useLocalStore(() => packageCartContext);
    const cms = useCms();
    const { section, query } = useExtendedQuery();
    const isAddExtraWorkflow = QueryUtils.getIsAddExtraWorkflow(query);
    const bookingService = useMMBBookingService();
    const navigationMap = useNavigationMap();
    const [navMap, setNavMap] = useState<Map<string, MenuItem[]>>(new Map());

    const firstTraveler = useMemo(() => {
      if (bookingService.booking && bookingService.booking.travelers.length > 0) {
        return bookingService.booking.travelers[0];
      }
    }, [bookingService.booking]);

    function onErrorAction() {
      setShowErrorModal(true);
    }

    useMount(() => {
      const currentPath = location.pathname;
      const noNavigationBackModal =
        location.state?.noNavigationBackModal ||
        manageBookingPages.includes(currentPath as PageUrl);
      if (
        !noNavigationBackModal &&
        PageUtil.getShoppingCartPagePaths().indexOf(currentPath) === -1 &&
        PageUtil.getShoppingCartPagePaths().some(value => value === prevPagePath) &&
        packageCartStore.cart
      ) {
        setShowNavigationBackModal(true);
      }

      if (bookingService.booking) {
        const booking = bookingService.booking;
        console.debug(bookingService.booking);
        Promise.all([
          cms.fetchBookingFulfillsCancellationRules(booking.bookingNumber),
          cms.fetchBookingFulfillsRebookingRules(booking.bookingNumber)
        ])
          .then(([bookingFulfillsCancellationRules, bookingFulfillsRebookingRules]) => {
            const isAdditionalNightAvailable = dayjs(booking.travelStartDate).isAfter(
              dayjs().add(cms.featureConfiguration.additionalNightDaysBeforeArrival, 'day')
            );
            const isCancellationAvailable =
              bookingFulfillsCancellationRules.get(VoucherCancellationType.ENTIRE) &&
              BookingUtil.isBookingFullyPaid(booking);

            const isCancellationIntoVoucherAvailable = bookingFulfillsCancellationRules.get(
              VoucherCancellationType.VOUCHER
            );
            setNavMap(
              MMBUtil.sortNavigationMapByTiles(
                navigationMap,
                cms.dashboardTiles,
                cms.featureConfiguration,
                section.build,
                bookingService.booking || undefined,
                isAdditionalNightAvailable,
                isCancellationIntoVoucherAvailable,
                isCancellationAvailable,
                bookingFulfillsRebookingRules
              )
            );
          })
          .catch(() => {});
      }
    });

    useEffect((): void => {
      if (manageBookingPages.includes(location.pathname as PageUrl)) {
        setShowWorkflowTabs(false);
      } else {
        setShowWorkflowTabs(
          cms.workflows.length > 1 || b2bPages.includes(location.pathname as PageUrl)
        );
      }
    }, [cms.workflows, location]);

    function shouldShowShoppingCart(): boolean {
      return ![
        ...manageBookingPages,
        PageUrl.CHECKOUT,
        PageUrl.HOTEL_LIST,
        PageUrl.CONFIRMATION
      ].includes(location.pathname as PageUrl);
    }

    function shouldShowLoggedInButton(): boolean {
      return !!authState.user && !authState.isGuest && !authState.agency?.number;
    }

    function shouldShowMultiLevelNavigation(): boolean {
      return innerManageBookingPages.includes(location.pathname as PageUrl);
    }

    const UserMenuLoggedIn = (): JSX.Element => (
      <>
        {!isAddExtraWorkflow && (
          <div className="mx-2 px-2 salutation-text">
            {firstTraveler &&
              `${t(Keys.salutationPrefix)}${firstTraveler.firstName}${t(Keys.salutationSuffix)}`}
          </div>
        )}
        <Button
          className="user-logged-in-button"
          color="primary"
          onClick={(): void => {
            history.push(PageUrl.LOGOUT);
          }}
        >
          <FontAwesomeIcon icon={faDoorOpen} />
          <span className="mx-2 px-2">{t(Keys.Logout)}</span>
        </Button>
      </>
    );

    return (
      <HeaderContainer>
        {showWorkflowTabs && <WorkflowTabs />}
        <div className="d-flex align-items-center mb-3 justify-content-end">
          {authState.isAgency &&
          (authState.role === ApiRole.AGENT || authState.role === ApiRole.AGENCYOWNER) ? (
            <AgencyMenu
              userName={
                authState.user ? `${authState.user.firstName} ${authState.user.lastName}` : ''
              }
            />
          ) : (
            <ManageBookingLink />
          )}
          <div className="d-flex align-items-center">
            {shouldShowMultiLevelNavigation() && (
              <MultiLevelNavigationMenu isVisible menuItemsMap={navMap} className="d-md-none" />
            )}
            {shouldShowLoggedInButton() && <UserMenuLoggedIn />}
            {shouldShowShoppingCart() && (
              <SummaryPopoverWithActions
                header={sessionStorage.getItem('PACKAGE_NAME') || undefined}
                onGoToCheckout={onGoToCheckout}
                editBookingItemExtension={editBookingItemExtension}
                onBookingChange={(bookingExist: boolean) => setIsCartDisplayed(bookingExist)}
                packageIncludedServices={packageIncludedServices}
                onErrorAction={onErrorAction}
                packageModel={packageModel}
              />
            )}
          </div>
        </div>
        {!!breadcrumbProps && (
          <ImageProgressbar
            steps={breadcrumbProps.items.map((step: MenuItem, idx: number) => ({
              description: step.label,
              image: faArrowRight,
              selected: idx === breadcrumbProps.activeIndex
            }))}
            showNotActiveIcon
            onClick={(stepIndex: number, description: string) => {
              breadcrumbProps.onClickAction(
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                breadcrumbProps.items.find(item => item.label === description)!,
                stepIndex
              );
            }}
          />
        )}
        <Modal
          className="header_navigationBack-modal"
          isOpen={showNavigationBackModal}
          backdrop={true}
          toggle={() => {
            setShowNavigationBackModal(!showNavigationBackModal);
            packageCartStore.resetCart();
          }}
          container={document.getElementById('iso') || undefined}
        >
          <ModalHeader>{t(Keys.navigationBackModal.header)}</ModalHeader>
          <ModalBody id="header_navigationBack-modal">
            {t(Keys.navigationBackModal.content)}
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col xs={12} md={'auto'} className="mb-2">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={(): void => {
                    setShowNavigationBackModal(false);
                    packageCartStore.resetCart();
                  }}
                >
                  {t(Keys.navigationBackModal.back)}
                </button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>

        <Modal
          className="hotelDetails-modal"
          isOpen={showErrorModal}
          backdrop={true}
          container={document.getElementById('iso') || undefined}
        >
          <ModalBody id={'hotelDetails-modal'}>
            <Row>
              <Col>
                <Row className="justify-content-center">
                  <div className="text-center px-2 py-1">
                    <Error message={t(Keys.errorModalInformation)} />
                  </div>
                </Row>
                <Row className="justify-content-center">
                  <Button
                    className="btn-primary"
                    onClick={(): void => {
                      setShowErrorModal(false);
                    }}
                  >
                    <div className="text-center px-2 py-1">{t(Keys.errorModalButtonLabel)}</div>
                  </Button>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </HeaderContainer>
    );
  }
);

export default Header;
