/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-08-07 12:14:09 */

const Keys = {
  yourBooking: { hint: 'yourBooking.hint', yourBooking: 'yourBooking.yourBooking' },
  booking: { hint: 'booking.hint', booking: 'booking.booking' },
  home: 'home',
  dashboard: 'dashboard',
  bookingOverview: {
    hint: 'bookingOverview.hint',
    bookingOverview: 'bookingOverview.bookingOverview',
    participants: 'bookingOverview.participants',
    bookedServices: 'bookingOverview.bookedServices',
    address: 'bookingOverview.address'
  },
  paymentStatus: {
    hint: 'paymentStatus.hint',
    paymentStatus: 'paymentStatus.paymentStatus'
  },
  documents: { hint: 'documents.hint', documents: 'documents.documents' },
  changeBooking: {
    hint: 'changeBooking.hint',
    changeBooking: 'changeBooking.changeBooking',
    rebookToDate: 'changeBooking.rebookToDate',
    additionalNight: 'changeBooking.additionalNight',
    rebook: 'changeBooking.rebook',
    voucher: 'changeBooking.voucher',
    cancelBooking: 'changeBooking.cancelBooking'
  },
  extraServices: {
    hint: 'extraServices.hint',
    extraServices: 'extraServices.extraServices'
  },
  bookingNotes: {
    hint: 'bookingNotes.hint',
    bookingNotes: 'bookingNotes.bookingNotes'
  },
  information: { hint: 'information.hint', information: 'information.information' },
  currentNews: { hint: 'currentNews.hint', currentNews: 'currentNews.currentNews' },
  video: { hint: 'video.hint', video: 'video.video' },
  packageBooking: {
    packages: 'packageBooking.packages',
    hotelList: 'packageBooking.hotelList',
    hotelDetails: 'packageBooking.hotelDetails',
    extras: 'packageBooking.extras',
    checkout: 'packageBooking.checkout'
  },
  extrasWorkflow: {
    extraTypes: 'extrasWorkflow.extraTypes',
    extrasList: 'extrasWorkflow.extrasList',
    checkout: 'extrasWorkflow.checkout'
  }
}; export default Keys;