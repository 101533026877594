import { useTranslation, MenuItem } from '@ibe/components';
import { useState } from 'react';
import Keys from '../Translations/generated/navigation-map.en.json.keys';
/* eslint import/no-cycle: "off" */
import { essentialPages, PageUrl } from '../pages';
import fallback from '../Translations/generated/navigation-map.en.json';
import useCms from './useCms';
import useExtendedQuery from './useExtendedQuery';
import { useLocation } from 'react-router-dom';

function UseNavigationMap(): Map<string, MenuItem[]> {
  const [navMap] = useState(new Map<string, MenuItem[]>());
  const { t } = useTranslation('navigation-map', fallback);
  const { section } = useExtendedQuery();
  const { pathname } = useLocation();
  const cms = useCms();
  const NAV_KEY_SUFFIX = '_key';

  navMap.set(
    PageUrl.DASHBOARD + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.DASHBOARD,
        t(Keys.yourBooking.yourBooking),
        t(Keys.yourBooking.hint),
        null,
        true
      )
    )
  );

  navMap.set(
    PageUrl.MANAGE_BOOKING + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.MANAGE_BOOKING,
        t(Keys.bookingOverview.bookingOverview),
        t(Keys.bookingOverview.hint),
        null,
        true
      ),
      new MenuItem(
        PageUrl.MANAGE_BOOKING + section.build('booking-overview'),
        t(Keys.bookingOverview.bookingOverview)
      ),
      new MenuItem(
        PageUrl.MANAGE_BOOKING + section.build('participants'),
        t(Keys.bookingOverview.participants)
      ),
      new MenuItem(
        PageUrl.MANAGE_BOOKING + section.build('booked-services'),
        t(Keys.bookingOverview.bookedServices)
      ),
      new MenuItem(
        PageUrl.MANAGE_BOOKING + section.build('address'),
        t(Keys.bookingOverview.address)
      )
    )
  );

  navMap.set(
    PageUrl.PAYMENT_STATUS + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.PAYMENT_STATUS,
        t(Keys.paymentStatus.paymentStatus),
        t(Keys.paymentStatus.hint)
      )
    )
  );

  navMap.set(
    PageUrl.DOCUMENTS + NAV_KEY_SUFFIX,
    Array.of(new MenuItem(PageUrl.DOCUMENTS, t(Keys.documents.documents), t(Keys.documents.hint)))
  );

  navMap.set(
    PageUrl.CHANGE_BOOKING + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.CHANGE_BOOKING,
        t(Keys.changeBooking.changeBooking),
        t(Keys.changeBooking.hint),
        null,
        true
      ),
      // new MenuItem(PageUrl.CHANGE_BOOKING + section.build("rebook-to-date"), t(Keys.changeBooking.rebookToDate)),
      new MenuItem(
        PageUrl.CHANGE_BOOKING + section.build('additionalNight'),
        t(Keys.changeBooking.additionalNight),
        null,
        'additionalNight'
      ),
      new MenuItem(
        PageUrl.CHANGE_BOOKING + section.build('rebook'),
        t(Keys.changeBooking.rebook),
        null,
        'rebook'
      ),
      new MenuItem(
        PageUrl.CHANGE_BOOKING + section.build('voucher'),
        t(Keys.changeBooking.voucher),
        null,
        'voucher'
      ),
      new MenuItem(
        PageUrl.CHANGE_BOOKING + section.build('cancel-booking'),
        t(Keys.changeBooking.cancelBooking),
        null,
        'cancel-booking'
      )
    )
  );

  navMap.set(
    PageUrl.EXTRA_SERVICES + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.EXTRA_SERVICES,
        t(Keys.extraServices.extraServices),
        t(Keys.extraServices.hint),
        null,
        false
      )
    )
  );

  navMap.set(
    PageUrl.BOOKING_NOTES + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.BOOKING_NOTES,
        t(Keys.bookingNotes.bookingNotes),
        t(Keys.bookingNotes.hint)
      )
    )
  );

  navMap.set(
    PageUrl.INFORMATION + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(PageUrl.INFORMATION, t(Keys.information.information), t(Keys.information.hint))
    )
  );

  navMap.set(
    PageUrl.VIDEO + NAV_KEY_SUFFIX,
    Array.of(new MenuItem(PageUrl.VIDEO, t(Keys.video.video), t(Keys.video.hint)))
  );

  navMap.set(
    PageUrl.PACKAGE + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.PACKAGE,
        t(Keys.packageBooking.packages),
        t(Keys.packageBooking.packages),
        null,
        true
      ),
      new MenuItem(
        PageUrl.HOTEL_LIST,
        t(Keys.packageBooking.hotelList),
        t(Keys.packageBooking.hotelList),
        null,
        true
      ),
      new MenuItem(
        PageUrl.HOTEL_LIST,
        t(Keys.packageBooking.hotelDetails),
        t(Keys.packageBooking.hotelDetails),
        null,
        true
      ),
      new MenuItem(
        PageUrl.EXTRAS_PAGE,
        t(Keys.packageBooking.extras),
        t(Keys.packageBooking.extras),
        null,
        true
      ),
      new MenuItem(
        PageUrl.CHECKOUT,
        t(Keys.packageBooking.checkout),
        t(Keys.packageBooking.checkout),
        null,
        true
      )
    )
  );

  navMap.set(
    PageUrl.HOTEL_WORKFLOW + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.HOTEL_WORKFLOW,
        t(Keys.packageBooking.hotelList),
        t(Keys.packageBooking.hotelList),
        null,
        true
      ),
      new MenuItem(
        PageUrl.HOTEL_WORKFLOW,
        t(Keys.packageBooking.hotelDetails),
        t(Keys.packageBooking.hotelDetails),
        null,
        true
      ),
      new MenuItem(
        PageUrl.CHECKOUT,
        t(Keys.packageBooking.checkout),
        t(Keys.packageBooking.checkout),
        null,
        true
      )
    )
  );

  navMap.set(
    PageUrl.EXTRAS_LIST_PAGE + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.EXTRAS_TYPE_LIST,
        t(Keys.extrasWorkflow.extraTypes),
        t(Keys.extrasWorkflow.extraTypes),
        null,
        true
      ),
      new MenuItem(
        PageUrl.EXTRAS_LIST_PAGE,
        t(Keys.extrasWorkflow.extrasList),
        t(Keys.extrasWorkflow.extrasList),
        null,
        true
      ),
      new MenuItem(
        PageUrl.EXTRAS_CHECKOUT,
        t(Keys.extrasWorkflow.checkout),
        t(Keys.extrasWorkflow.checkout)
      )
    )
  );

  navMap.set(
    PageUrl.HOTEL_LIST + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.HOTEL_LIST,
        t(Keys.packageBooking.hotelList),
        t(Keys.packageBooking.hotelList),
        null,
        true
      ),
      new MenuItem(
        PageUrl.HOTEL_LIST,
        t(Keys.packageBooking.hotelDetails),
        t(Keys.packageBooking.hotelDetails),
        null,
        true
      ),
      new MenuItem(
        PageUrl.EXTRAS_PAGE,
        t(Keys.packageBooking.extras),
        t(Keys.packageBooking.extras),
        null,
        true
      ),
      new MenuItem(
        PageUrl.CHECKOUT,
        t(Keys.packageBooking.checkout),
        t(Keys.packageBooking.checkout),
        null,
        true
      )
    )
  );

  navMap.set(
    PageUrl.EXTRAS_PAGE + NAV_KEY_SUFFIX,
    Array.of(
      new MenuItem(
        PageUrl.EXTRAS_PAGE,
        t(Keys.packageBooking.extras),
        t(Keys.packageBooking.extras),
        null,
        true
      ),
      new MenuItem(
        PageUrl.CHECKOUT,
        t(Keys.packageBooking.checkout),
        t(Keys.packageBooking.checkout),
        null,
        true
      )
    )
  );

  if (pathname === PageUrl.DASHBOARD) {
    const tileKeys = [...cms.dashboardTiles.keys()].map(key => `/${key}${NAV_KEY_SUFFIX}`);

    navMap.forEach((value, key, map) => {
      if (!tileKeys.includes(key) && !essentialPages.find(page => page + NAV_KEY_SUFFIX === key)) {
        map.delete(key);
      }
    });
  }
  return navMap;
}

export default UseNavigationMap;
