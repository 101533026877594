import { ApiBookingState, ApiPaymentState, ApiPaymentOption } from '@ibe/api';
import fallback from '../../../Translations/generated/booking-overview.en.json';
import Keys from '../../../Translations/generated/booking-overview.en.json.keys';
import { useTranslation } from '@ibe/components';

function BookingTranslationsUtil(): {
  mapStatus: (status: ApiBookingState) => string;
  mapPaymentStatus: (status: ApiPaymentState) => string;
  mapPaymentType: (type: ApiPaymentOption) => string;
} {
  const { t } = useTranslation('booking-overview', fallback, Keys);

  function mapStatus(status: ApiBookingState): string {
    return status ? t(`statuses.bookingStatuses.${status}`) : t(Keys.statuses.unknown);
  }

  function mapPaymentStatus(status: ApiPaymentState): string {
    return status
      ? t(`statuses.paymentStatuses.${status}`)
      : t(Keys.statuses.paymentStatuses.UNDEFINED);
  }

  function mapPaymentType(type: ApiPaymentOption): string {
    return type ? t(`payment.type.${type}`) : t(Keys.payment.type.UNDEFINED);
  }

  return {
    mapStatus,
    mapPaymentStatus,
    mapPaymentType
  };
}

export default BookingTranslationsUtil;
